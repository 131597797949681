<template>
  <div id="digital" class="gallery">
    <slot />
  </div>
</template>

<script>
export default {
    name: "GalleryComp"
}
</script>

<style lang="scss">

@import "../styles/common";
    
</style>